<template>
  <div class="panel-header panel-header-sm"
       :class="areas.length > 1 && areas.length <= 8 ? 'show-subheader' : 'hide-subheader'"/>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'overview-header',
  components: {},
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      areas: 'area/areas'
    })
  }
}
</script>
<style scoped>
.hide-subheader {
    min-height: 0;
    height: 0;
    padding-top: 10px;
}

.show-subheader {
    min-height: 40px;
    height: 40px;
    padding-top: 40px;
}
</style>
